<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <h3 class="mb-5">Importeer Excel-bestand</h3>
        <v-row>
          <v-col class="pa-0" cols="12">
            <v-select
              v-if="auctions"
              v-model="selectedAuctionExcel"
              :items="auctions"
              label="Selecteer een veiling"
              class="property-filter"
              hide-no-data
              item-value="_id"
              item-text="Data.Name.Value.Nl"
              @change="fillLots(selectedAuctionExcel)"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0" cols="12">
            <input
              :ref="'excelInput'"
              class="fileInput"
              type="file"
              v-on:change="setSelectedExcel($event.target.files)"
            />
            <v-btn @click="$refs['excelInput'].click()" class="mb-5" outlined
              >Seleceer een Excel-bestand</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0" cols="12">
            <h3 class="mt-3 mb-3" v-if="selectedExcel">
              {{ selectedExcel.name }}
            </h3>

            <v-btn
              :disabled="!(selectedExcel && selectedAuctionExcel)"
              :loading="uploadingExcel"
              v-on:click="submitExcel(selectedExcel)"
            >
              Uploaden<v-icon right dark>cloud_upload</v-icon>
            </v-btn>

            <v-alert
              v-if="uploadExcelError.length > 0"
              class="mt-3"
              color="error"
              icon="warning"
              :value="true"
            >
              {{ uploadExcelError }}
            </v-alert>
            <v-alert
              v-if="processedAllAutoWinners.length > 0"
              class="mt-3"
              type="success"
              dismissible
              :value="true"
            >
              {{ processedAllAutoWinners }}
            </v-alert>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-if="autoCompletedWinners.length != 0" class="mb-4">
      <v-card-title> Automatisch Verwerken </v-card-title>
      <v-card-text>
        <v-row>
          <div class="d-flex align-center">
            <p class="ma-0">
              Aantal automatisch ingevulde winnaars (<span>{{
                amountOfAutoAdded
              }}</span
              >)
            </p>
          </div>
          <v-layout justify-end align-center>
            <v-btn
              :loading="pocessLoading"
              color="primary"
              @click="processWinners"
            >
              Verwerken (<span>{{ amountOfAutoAdded }}</span
              >)
            </v-btn>
          </v-layout>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-4" v-if="winners.length != 0">
      <v-card-title>Winnaars koppelen aan klanten</v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="pa-0" cols="12">
            <div v-for="(winner, index) in winners" :key="'winner' + index">
              <!--  {{ winner }} -->

              <v-row
                v-if="!winner.Canceled"
                class="buyer-row"
                justify="center"
                align="center"
              >
                <v-col class="buyer-left-column pl-0 pr-0" cols="2">
                  <template v-if="winner.Client == null">
                    <v-chip
                      class="mr-2 float-left"
                      color="green"
                      text-color="white"
                      small
                    >
                      Nieuw
                    </v-chip>
                  </template>
                  <template v-else>
                    <v-chip
                      class="mr-2 float-left"
                      color="orange"
                      text-color="white"
                      small
                      v-model="attrs"
                      v-on="on"
                    >
                      Gekoppeld
                    </v-chip>
                  </template>

                  <h4>
                    Kopernummer: <b>{{ winner.BuyerNumber }}</b>
                  </h4>
                  <v-row
                    class="d-flex justify-start clear"
                    v-if="winner.IsExistingClient"
                  >
                    <v-col cols="12" class="pl-0">
                      <span v-if="winner.ClientId" class="font-weight-bold">
                        {{ findUserName(winner.ClientId).name }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-expansion-panels
                    v-else
                    class="py-4 pr-4 d-flex justify-start"
                  >
                    <v-expansion-panel v-if="!searchItem[index]">
                      <v-expansion-panel-header v-slot="{ open }" class="pa-2">
                        <v-fade-transition leave-absolute>
                          <span v-if="open" key="0">
                            Selecteer een klant
                          </span>

                          <span v-else key="1">
                            Selecteer een klant
                          </span>
                        </v-fade-transition>
                      </v-expansion-panel-header>
                      <!-- {{ winner }} -->
                      <v-expansion-panel-content>
                        <!--winner.IsExistingClient-->
                        <!--   todo: client dropdown -->
                        <v-row class="d-flex align-center mb-2">
                          <p class="mb-0">Nieuwe klant</p>
                          <v-layout justify-end>
                            <newDialog :dailogItems="newClientDialog" />
                          </v-layout>
                        </v-row>
                        <v-row>
                          <v-combobox
                            v-if="clients"
                            clearable
                            dense
                            persistent-hint
                            v-model="searchItem[index]"
                            label="Selecteer een klant"
                            :items="clients"
                            item-text="name"
                            item-value="id"
                            class="mr-2"
                          ></v-combobox>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <div v-else>
                      <span class="font-weight-bold">
                        {{ searchItem[index].name }}
                      </span>
                      <v-btn icon @click="sliceOne('client', index)">
                        <v-icon> close </v-icon>
                      </v-btn>
                    </div>
                  </v-expansion-panels>

                  <v-select
                    v-model="winner.BuyerType"
                    class="pr-2"
                    :items="buyerTypes"
                    label="Selecteer een type"
                    hide-no-data
                    item-value="value"
                    item-text="name"
                    return-object
                  ></v-select>
                </v-col>
                <v-col class="pl-0 pr-0" cols="8">
                  <v-row>
                    <v-col cols="2">
                      <b>Lotnummer</b>
                    </v-col>
                    <v-col cols="8">
                      <b>Omschrijving</b>
                    </v-col>
                    <v-col cols="2">
                      <b>Prijs</b>
                    </v-col>
                  </v-row>
                  <v-row
                    v-for="(lot, index) in winner.Lots"
                    :key="'lot' + index"
                  >
                    <v-col class="lot-column" cols="2">
                      <!-- {{ lot }} -->
                      {{ lot.Number }}
                    </v-col>
                    <v-col v-if="lot.Id.length > 0" class="lot-column" cols="8">
                      {{ lot.Description }}
                    </v-col>
                    <v-col v-else class="lot-column" cols="8">
                      <span v-if="selectedLot[lot.Number]">
                        {{ selectedLot[lot.Number].DisplayLabel }}
                        <v-btn icon @click="sliceOne('lot', lot.Number)">
                          <v-icon> close </v-icon>
                        </v-btn>
                      </span>
                      <v-expansion-panels v-else>
                        <v-expansion-panel>
                          <v-expansion-panel-header v-slot="{ open }">
                            <v-fade-transition leave-absolute>
                              <span v-if="open" key="0">
                                Selecteer een lot of maak een nieuw lot aan.
                              </span>

                              <span v-else key="1">
                                Lot {{ lot.Number }}
                                <b>{{ lot.Description }}</b> niet gevonden.<br />
                              </span>
                            </v-fade-transition>
                          </v-expansion-panel-header>
                          <!-- {{ winner }} -->
                          <v-expansion-panel-content>
                            <v-combobox
                              class="pr-2"
                              v-model="selectedLot[lot.Number]"
                              :items="allLots"
                              label="Selecteer een lot"
                              hide-no-data
                              item-value="_id"
                              item-text="DisplayLabel"
                              return-object
                              @update:search-input="findLot"
                            ></v-combobox>
                            <v-row>
                              <v-layout align-center>
                                <p class="ma-0 mr-2">Nieuw lot aanmaken</p>
                                <newDialog :dailogItems="newLotDialog" />
                              </v-layout>
                            </v-row>
                          </v-expansion-panel-content>
                          <v-expansion-panel-content>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                    <v-col class="lot-column" cols="2">
                      € {{ lot.MaxPrice }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="pl-0 pr-0" cols="2">
                  <v-layout wrap>
                    <v-btn
                      small
                      class="mr-3 mb-3"
                      v-on:click="cancelAddWinner(winner)"
                    >
                      Annuleren
                    </v-btn>
                    <br />

                    <v-btn
                      small
                      color="primary"
                      v-on:click="addWinner(index)"
                      :loading="loading[index]"
                      :dark="
                        (searchItem[index] || winner.IsExistingClient) &&
                        lookForSelectedLot(index)
                      "
                      :disabled="
                        !(
                          (searchItem[index] || winner.IsExistingClient) &&
                          lookForSelectedLot(index)
                        )
                      "
                    >
                      Verwerk
                      <v-icon small>add</v-icon>
                    </v-btn>
                  </v-layout>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import request from "../../request";
import Vue from "vue";

export default {
  data() {
    return {
      test: "",
      amountOfAutoAdded: 0,
      auctions: null,
      selectedExcel: null,
      selectedAuctionExcel: null,
      uploadingExcel: false,
      uploadExcelError: "",
      winners: [],
      selectedLot: [],
      dialog: false,
      searchItem: [],
      buyerTypes: [
        { name: "Schriftelijk", value: 1 },
        { name: "Telefonish", value: 2 },
        { name: "Zaal", value: 3 },
        { name: "Drouot", value: 4 },
      ],
      clients: [],
      addidWinners: [],
      autoCompletedWinners: [],
      allLots: null,
      newClientDialog: {
        icon: "add",
        title: "Nieuwe klant",
        AddNewUser: true,
        AddNewLot: false,
        needsDetailsCheckbox: true,
      },
      newLotDialog: {
        icon: "add",
        title: "Nieuw lot aanmaken",
        AddNewUser: false,
        AddNewLot: true,
        auctions: null,
        needsDetailsCheckbox: false,
      },
      selectedClient: {},
      loading: [],
      pocessLoading: false,
      processedAllAutoWinners:""
    };
  },
  created() {
    request.get("/pagecontent/5fa3b4eaae990f3950fe0687", null, (res) => {
      if (res.success) {
        this.auctions = res.data;
        this.newLotDialog.auctions = res.data;
      }
    }),
      request.get(
        "/pagecontentsearch/5fa3b4eaae990f3950fe068a?searchValue=&",
        null,
        (res) => {
          if (res.success) {
            res.data.forEach((element) => {
              let fristname = element.Data.FirstName.Value.Nl,
                lastname = element.Data.LastName.Value.Nl;
              this.clients.push({
                id: element._id.$oid,
                name: fristname + " " + lastname,
              });
            });
          }
        }
      )

  },
  methods: {
    fillLots(auction){
      request.get(`/pagecontent/5fa3b4eaae990f3950fe0688?start=0&sortBy=&sortDescending=false&search=&filters=Auction:${auction.$oid}&`, null, (res) => {
        if (res.success) {
          this.allLots = res.data;
          console.log(this.allLots);
        }
      });
    },
    setSelectedExcel(files) {
      this.selectedExcel = files[0];
    },
    submitExcel(file) {
      this.uploadingExcel = true;
      this.uploadExcelError = "";

      let formData = new FormData();
      formData.append("file", file);
      request.post(
        "/custom/uploadwinnersexcel/?auctionId=" +
          this.selectedAuctionExcel.$oid,
        formData,
        (res) => {
          this.uploadingExcel = false;
          this.$refs["excelInput"].value = null;
          if (res.success) {
            const filterArr = (item) => {
              if (item.Valid) {
                return item;
              } else {
                this.winners.push(item);
              }
            };
            this.autoCompletedWinners.push(...res.filter(filterArr));
            this.amountOfAutoAdded = this.autoCompletedWinners.length;
          } else {
            this.uploadExcelError = res.message;
          }
        }
      );
    },
    cancelAddWinner(winner) {
      winner.Canceled = true;
      Vue.set(
        this.winners,
        this.winners.findIndex((f) => f.BuyerNumber === winner.BuyerNumber),
        winner
      );
    },
    addWinner(index) {
      this.loading[index] = true;
      let toAddItmes = [];
      let lot;
      for (const item of this.winners[index].Lots) {
        console.log(item);
        console.log(this.selectedLot[item.Number]);
        if (!this.selectedLot[item.Number]) {
          lot = {
            Id: item.Id,
            Number: item.Number,
            Category: item.Category,
            Description: item.Description,
            DescriptionDetail: item.DescriptionDetail,
            Height: item.Height,
            MaximumPrice: item.MaxPrice,
            MinimumPrice: item.MinPrice,
            Width: item.Width,
          };
          toAddItmes.push(lot);
        }
        lot = {
          Id: this.selectedLot[item.Number]._id.$oid,
          Number: this.selectedLot[item.Number].Data.ArticleNumber.Value,
          Category: this.selectedLot[item.Number].Data.Category.ValueSort,
          Description: this.selectedLot[item.Number].Data.Description.Value.Nl,
          DescriptionDetail:
            this.selectedLot[item.Number].Data.DescriptionDetail.Value.Nl,
          Height: this.selectedLot[item.Number].Data.Height.Value,
          MaximumPrice: this.selectedLot[item.Number].Data.MaximumPrice.Value,
          MinimumPrice: this.selectedLot[item.Number].Data.MinimumPrice.Value,
          Width: this.selectedLot[item.Number].Data.Width.Value,
        };
        toAddItmes.push(lot);
      }

      this.winners[index].Lots = toAddItmes.filter(function (x) {
        return x !== undefined;
      });
      if (!this.winners[index].ClientId) {
        this.winners[index].ClientId = this.searchItem[index].id;
      }
      this.winners[index].Valid = true;
      this.winners[index].IsExistingClient = true;
      request.post(
        "/custom/progresswinners/?auctionId=" + this.selectedAuctionExcel.$oid,
        [this.winners[index]],
        (res) => {
          this.loading[index] = false;
          if (res.success) {
            let first, second;
            first = this.winners.slice(0, index);
            second = this.winners.slice(index + 1);
            this.winners = [...first, ...second];
            delete this.searchItem[index];
          } else {
            this.error = res.message;
          }
        }
      );
    },
    findUserName(id) {
      return this.clients.find((element) => element.id === id);
    },
    findLot(value) {
      if (value != "" && value) {
        request.get(
          "/pagecontent/5fa3b4eaae990f3950fe0688?search=" +
            value +
            `&filters=Auction:${this.selectedAuctionExcel.$oid}&`,
          null,
          (res) => {
            if (res.success) {
              this.allLots = res.data;
            } else {
              this.error = res.message;
            }
          }
        );
      }
    },
    processWinners() {
      this.pocessLoading = true;
      console.log(this.autoCompletedWinners);
      request.post(
        "/custom/progresswinners/?auctionId=" + this.selectedAuctionExcel.$oid,
        this.autoCompletedWinners,
        (res) => {
          this.pocessLoading = false;
          console.log(res);
          if (res.success) {
            this.autoCompletedWinners = [];
            this.processedAllAutoWinners="Alle winnaars zijn verwerkt. Koppel nog alle onderstaande winnaars aan klanten!"
          } else {
            this.error = res.message;
          }
        }
      );
    },
    sliceOne(item, nr) {
      if (item === "lot") {
        this.selectedLot = [
          ...this.selectedLot.slice(0, nr),
          (this.selectedLot[nr] = ""),
          ...this.selectedLot.slice(nr + 1),
        ];
        return;
      }
      this.searchItem = [
        ...this.searchItem.slice(0, nr),
        (this.selectedLot[nr] = ""),
        ...this.searchItem.slice(nr + 1),
      ];
    },
    lookForSelectedLot(index) {
      let lotArray = [];
      this.winners[index].Lots.forEach((element) => {
        if (!this.selectedLot[element.Number] && element.Id === "") {
          return false;
        }

        lotArray.push(true);
      });
      if (lotArray.length === this.winners[index].Lots.length) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
.buyer-row {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.buyer-left-column {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}

.lot-column {
  padding-top: 0;
  padding-bottom: 0;
}
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.clear {
  clear: both;
}
</style>
